import React from 'react'

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
            <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
                <div className='sm:text-right pb-8 pl-4'>
                    <p className='text-4xl font-bold inline border-b-4 border-[#41b883]'>About</p>
                </div>
                <div>
                </div>       
            </div>
            <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
                <div className='sm:text-right text-4xl font-bold'>
                    <p>Hi. I'm Brandon, nice to meet you. Please take a look around.</p>
                </div>
                <div>
                    <p>I'm a recent graduate from Cal Poly Pomona with a B.S. in Computer Information Systems and an emphasis in Applications Development. I'm also 
                        an IT professional with working experience in the health services and education industries. I'm eager to prove my value and motivated to advance 
                        and expand my skill set through targeted mentorship and challenging projects.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About